import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    const addable = this.element.dataset.addable === "true";

    this.select = new SlimSelect({
      select: this.element,
      addable: addable ? this.addableFunction : false
    });
  }

  addableFunction(value) {
    // Return false or null if you do not want to allow the value to be submitted
    if (value === "bad") {
      return false;
    }

    // Return the value string
    return value; // Optional - value alteration, e.g., value.toLowerCase()

    // Optional - Return a valid data object. See methods/setData for list of valid options
    return {
      text: value,
      value: value.toLowerCase(),
    };
  }

  disconnect() {
    this.select.destroy();
  }
}
