import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['icon'];

  plusMinus() {
    if (this.iconTarget.classList.contains("fa-minus")) {
      this.iconTarget.classList.remove("fa-minus");
      this.iconTarget.classList.add("fa-plus");
    }
    else {
      this.iconTarget.classList.remove("fa-plus");
      this.iconTarget.classList.add("fa-minus");
    }
  }

  eye() {
    if (this.iconTarget.classList.contains("fa-eye")) {
      this.iconTarget.classList.remove("fa-eye");
      this.iconTarget.classList.add("fa-eye-slash");
    }
    else {
      this.iconTarget.classList.remove("fa-eye-slash");
      this.iconTarget.classList.add("fa-eye");
    }
  }

  chevron() {
    if (this.iconTarget.classList.contains("fa-chevron-down")) {
      this.iconTarget.classList.remove("fa-chevron-down");
      this.iconTarget.classList.add("fa-chevron-right");
    }
    else {
      this.iconTarget.classList.remove("fa-chevron-right");
      this.iconTarget.classList.add("fa-chevron-down");
    }
  }
}
