import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'list', 'hiddenInput'];

  async fetchBooks(query) {
    if (query.length >= 2) {
      try {
        const response = await fetch(`/catalog/books.json?q[number_cont]=${query}`);
        const books = await response.json();
        this.renderBooks(books.books);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    } else {
      this.listTarget.style.display = "none";
    }
  }

  renderBooks(books) {
    this.listTarget.style.display = "block";

    const list = this.listTarget;
    list.innerHTML = '';

    if (books.length === 0) {
      const listItem = document.createElement('li');
      listItem.classList.add('dropdown-item');
      listItem.textContent = 'No results found';
      list.appendChild(listItem);
    } else {
      books.forEach(book => {
        const listItem = document.createElement('li');
        listItem.classList.add(
          'dropdown-item',
          'cursor-pointer',
          'hover:text-dark_green',
          'hover:font-bold',
          'hover:underline',
          'focus-visible:text-dark_green',
          'focus-visible:outline-none',
          'focus-visible:font-bold',
          'focus-visible:underline',
          'dark:hover:text-light_green',
          'dark:focus-visible:text-light_green'
        );
        listItem.tabIndex = 0;
        listItem.dataset.action = 'click->book-search#handleSelect touch->book-search#handleSelect keydown.enter->book-search#handleSelect';
        listItem.dataset.id = book.id;
        listItem.textContent = book.number;
        list.appendChild(listItem);
      });
    }
  }

  handleSelect(event) {
    const selectedId = event.target.dataset.id;
    const selectedName = event.target.textContent;

    this.inputTarget.value = selectedName;
    this.hiddenInputTarget.value = selectedId;

    this.listTarget.style.display = 'none';
  }

  connect() {
    this.listTarget.style.display = "none";

    this.inputTarget.addEventListener('input', () => {
      const query = this.inputTarget.value;
      console.log(query)
      this.fetchBooks(query);
    });
  }
}
