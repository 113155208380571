import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video"];

  toggleVideo() {
    const videoSources = {
      'https://dfd0f4930559ff4a51e6-15f7ef1303d84c664f64552cead0dad7.ssl.cf1.rackcdn.com/meet-us/Sales%20Leaders%20Video%20V12.mp4':
        'https://dfd0f4930559ff4a51e6-15f7ef1303d84c664f64552cead0dad7.ssl.cf1.rackcdn.com/meet-us/Sales%20Leaders%20V11%20ADA.mp4',
      'https://dfd0f4930559ff4a51e6-15f7ef1303d84c664f64552cead0dad7.ssl.cf1.rackcdn.com/We%20Hire%20Smart%20People%20With%20Interesting%20Backgrounds%20%E2%80%93%20Join%20Our%20Team!_.mp4':
        'https://dfd0f4930559ff4a51e6-15f7ef1303d84c664f64552cead0dad7.ssl.cf1.rackcdn.com/ADA%20-%20VFF%20Carees%20V1.mp4',
    };

    const selectedVideo = this.videoTarget;
    const currentSrc = selectedVideo.src;

    const originalSrc = selectedVideo.dataset.originalSrc || '';

    if (videoSources[currentSrc]) {
      selectedVideo.setAttribute('src', videoSources[currentSrc]);
      selectedVideo.dataset.originalSrc = currentSrc;
    } else {
      selectedVideo.setAttribute('src', originalSrc);
    }
  }
}
