import { Controller } from "@hotwired/stimulus"

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in window ||
    "IntersectionObserverEntry" in window ||
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  );
}

export default class extends Controller {
  static targets = [
    "navBar",
    "scrollArea",
    "column",
    "leftButton",
    "rightButton",
    "columnVisibilityIndicator",
  ];
  static classes = [
    "navShown",
    "navHidden",
    "buttonDisabled",
    "indicatorVisible",
  ];

  connect() {
    this.startObservingColumnVisibility();
  }

  disconnect() {
    this.stopObservingColumnVisibility();
  }

  startObservingColumnVisibility() {
    if (!supportsIntersectionObserver()) {
      console.warn(`This browser doesn't support IntersectionObserver`);
      return;
    }

    this.intersectionObserver = new IntersectionObserver(
      this.updateScrollNavigation.bind(this),
      {
        root: this.scrollAreaTarget,
        threshold: 0.99, // otherwise, the right-most column sometimes won't be considered visible in some browsers, rounding errors, etc.
      }
    );

    this.columnTargets.forEach((headingEl) => {
      this.intersectionObserver.observe(headingEl);
    });
  }

  stopObservingColumnVisibility() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  updateScrollNavigation(observerRecords) {
    observerRecords.forEach((record) => {
      record.target.dataset.isVisible = record.isIntersecting;
    });

    this.toggleScrollNavigationVisibility();
    this.updateColumnVisibilityIndicators();
    this.updateLeftRightButtonAffordance();
  }

  toggleScrollNavigationVisibility() {
    const allColumnsVisible =
      this.columnTargets.length > 0 &&
      this.columnTargets[0].dataset.isVisible === "true" &&
      this.columnTargets[this.columnTargets.length - 1].dataset.isVisible ===
        "true";

    if (allColumnsVisible) {
      this.navBarTarget.classList.remove(this.navShownClass);
      this.navBarTarget.classList.add(this.navHiddenClass);
    } else {
      this.navBarTarget.classList.add(this.navShownClass);
      this.navBarTarget.classList.remove(this.navHiddenClass);
    }
  }

  updateColumnVisibilityIndicators() {
    this.columnTargets.forEach((headingEl, index) => {
      const indicator = this.columnVisibilityIndicatorTargets[index];

      if (indicator) {
        indicator.classList.toggle(
          this.indicatorVisibleClass,
          headingEl.dataset.isVisible === "true"
        );
      }
    });
  }

  updateLeftRightButtonAffordance() {
    const firstColumnHeading = this.columnTargets[0];
    const lastColumnHeading = this.columnTargets[this.columnTargets.length - 1];

    this.updateButtonAffordance(
      this.leftButtonTarget,
      firstColumnHeading.dataset.isVisible === "true"
    );
    this.updateButtonAffordance(
      this.rightButtonTarget,
      lastColumnHeading.dataset.isVisible === "true"
    );
  }

  updateButtonAffordance(button, isDisabled) {
    if (isDisabled) {
      button.setAttribute("disabled", "");
      button.classList.add(this.buttonDisabledClass);
    } else {
      button.removeAttribute("disabled");
      button.classList.remove(this.buttonDisabledClass);
    }
  }

  scrollLeft() {
    // scroll to the previous column to the left of the scroll area
    let currentIndex = this.columnTargets.findIndex(column => column.dataset.isVisible === "true");
    if (currentIndex > 0) {
      const columnToScrollTo = this.columnTargets[currentIndex - 1];
      this.scrollAreaTarget.scroll({
        left: columnToScrollTo.offsetLeft,
        behavior: 'smooth'
      });
    } else {
      // If the first column is already visible, scroll to the start
      this.scrollAreaTarget.scroll({
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  scrollRight() {
    // scroll to the next column to the right of the scroll area
    let currentIndex = this.columnTargets.findIndex(column => column.dataset.isVisible === "true");
    if (currentIndex < this.columnTargets.length - 1) {
      const columnToScrollTo = this.columnTargets[currentIndex + 1];
      this.scrollAreaTarget.scroll({
        left: columnToScrollTo.offsetLeft,
        behavior: 'smooth'
      });
    } else {
      // If the last column is already visible, scroll to the end
      const lastColumn = this.columnTargets[this.columnTargets.length - 1];
      this.scrollAreaTarget.scroll({
        left: lastColumn.offsetLeft + lastColumn.offsetWidth - this.scrollAreaTarget.clientWidth,
        behavior: 'smooth'
      });
    }
  }
}