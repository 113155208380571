import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.getElementById("search-results").style.display = "none";
  }

  showSearchBar() {
    let toggler = document.getElementById("dl-search-toggle");
    let searchBar = document.querySelector(".search-bar");
    let dropdown = document.getElementById("search-results");

    if (searchBar.style.display === "none") {
      searchBar.style.display = "block";
      toggler.classList.add("opened");
      toggler.classList.remove("unopened");
    } else {
      searchBar.style.display = "none";
      toggler.classList.add("unopened");
      toggler.classList.remove("opened");
      dropdown.classList.remove("show");
    }
  }

  debouncedSearch = this.debounce(this.search, 400);

  search() {
    const searchBar = document.getElementById("navsearch");
    const results = document.getElementById("search-results");
    const searchTerm = searchBar.value.trim(); // Trim whitespace

    if (searchTerm !== "") {
      results.style.display = "block";
      results.innerHTML = '<li class="dropdown-item">Loading...</li>';

      // Replace relative path with absolute path.
      const url = `${window.location.origin}/design-library.json?q[design_library_search_cont]=${searchTerm}`;

      fetch(url)
        .then((response) => response.json())
        .then((response) => {
          if (!response.items || response.items.length === 0) {
            results.innerHTML =
              '<li class="dropdown-item">No results found.</li>';
            return;
          }

          // If the current subdomain is stg or print.stg, the Design Library link should point to the stg subdomain.
          // Otherwise, it should point to the www subdomain.
          let requestSubdomain;
          const currentOrigin = window.location.origin;

          if (currentOrigin.includes("localhost")) {
            // Handle localhost for development or test environments.
            if (
              currentOrigin.includes("print.stg") ||
              currentOrigin.includes("stg")
            ) {
              requestSubdomain = currentOrigin.replace(
                /\/\/(?:print\.)?stg\./,
                "//stg."
              );
            } else {
              requestSubdomain = currentOrigin; // No subdomain change for localhost.
            }
          } else {
            if (
              currentOrigin.includes("print.stg") ||
              currentOrigin.includes("stg")
            ) {
              requestSubdomain = currentOrigin.replace(
                /\/\/(?:print\.)?stg\./,
                "//stg."
              );
            } else if (
              currentOrigin.includes("print") ||
              currentOrigin.includes("www") ||
              !currentOrigin.includes(".")
            ) {
              requestSubdomain = currentOrigin.replace(/\/\/[^.]+\./, "//www.");
            } else {
              requestSubdomain = currentOrigin; // Default to the current origin if it doesn't match the above cases.
            }
          }

          const items = response.items
            .map((product) => {
              return `<li><a href="${requestSubdomain}/design-library/${product.book}/${product.rotation}" class="dropdown-item">${product.description}</a></li>`;
              // product.description defined in design library index.json.jbuilder
            })
            .join("");

          // Construct the View All Results link
          const allResultsLink = `<li><a class="dropdown-item" href="${requestSubdomain}/design-library?q[design_library_search_cont]=${searchTerm}" id="search-dropdown"><span>View All results</span></a></li>`;

          results.innerHTML = items + allResultsLink;
        })
        .catch((error) => {
          console.error(
            "There was an error reaching the Design Library.",
            error
          );
          results.innerHTML =
            '<li class="dropdown-item">Error loading results</li>';
        });
    } else {
      results.style.display = "none";
    }
  }

  searchProducts() {
    let searchBar = document.getElementById("item_search_product_id");
    let results = document.getElementById("product-search-results");
    let searchTerm = searchBar.value;
    if (searchTerm !== "" && searchTerm.length > 2) {
      results.classList.add("block");
      results.classList.remove("hidden");

      fetch(
        `/catalog/products.json?q[legacy_sku_or_color_name_or_pattern_name_start]=${searchTerm}`
      )
        .then((response) => response.json())
        .then(function (response) {
          if (response.products.length === 0) {
            let results = `<li class="dropdown-item cursor-pointer hover:text-dark_green hover:dark:text-light_green">No results</li>`;
            let searchResults = document.getElementById(
              "product-search-results"
            );
            searchResults.innerHTML = results;
          } else {
            let results = response.products
              .map((product) => {
                return `<li data-action="click->search-bar#handleProductSelect" value="${product.id}" class="dropdown-item cursor-pointer hover:text-dark_green hover:dark:text-light_green">${product.description}</li>`;
              })
              .join("");
            let searchResults = document.getElementById(
              "product-search-results"
            );
            searchResults.innerHTML = results;
          }
        })
        .catch(function (error) {
          console.log(
            "There was an error reaching all products.",
            error.message
          );
        });
    } else {
      results.classList.add("hidden");
      results.classList.remove("block");
    }
  }

  handleProductSelect(e) {
    const id = e.target.attributes.value.nodeValue;
    const name = e.target.innerText;
    document.getElementById("item_product_id").setAttribute("value", id);
    document.getElementById("item_search_product_id").value = name;
    document.getElementById("product-search-results").classList.add("hidden");
  }

  debounce(func, wait, immediate) {
    var timeout;

    return function () {
      var context = this,
        args = arguments;
      var callNow = immediate && !timeout;
      clearTimeout(timeout);

      timeout = setTimeout(function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      }, wait);

      if (callNow) func.apply(context, args);
    };
  }
}
