import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    const form = document.getElementById("box-query-builder-form");
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.fetchQuery(e);
    });
  }

  fetchQuery(e) {
    document.getElementById("boxresults").innerHTML = "";
    document.getElementById("boxerrors").innerHTML = "";
    let loader = document.getElementById("boxloader");
    loader.classList.add("boxloader");
    let form = e.target;
    let formData = new FormData(form);
    let csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const { query, type } = Object.fromEntries(
      formData
    );

    fetch(`/box/query?query=${query}&type=${type}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          document.getElementById(
            "boxerrors"
          ).innerHTML = `<div class="alert alert-danger alert-dismissible" role="alert">Error: ${res.error}<a href="#" data-dismiss="alert" aria-label="close" class="close">&times</a></div>`;
          this.removeLoader();
          this.enableBtn();
        } else if (res.data.length === 0) {
          document.getElementById("boxresults").innerHTML =
            "<small>No results.</small>";
          this.removeLoader();
          this.enableBtn();
        } else {
          let count = res.data.length;
          let headerRows = Object.keys(res.data[0])
            .filter((key) => !["attributes"].includes(key))
            .map((key) => {
              return `<th>${key}</th>`;
            })
            .join("");
          let dataColumns = res.data
            .map((col) => {
              delete col.attributes;
              let tds = Object.values(col)
                .map((value) => {
                  return `<td>${value}</td>`;
                })
                .join("");

              return `<tr>${tds}</tr>`;
            })
            .join("");
          document.getElementById(
            "box-results-count"
          ).innerHTML = `<div>${count} results.</div>`;
          document.getElementById("boxresults").innerHTML = `
            <thead>
              ${headerRows}
            </thead>
            <tbody>
              ${dataColumns}
            </tbody>
          `;
          this.removeLoader();
          this.enableBtn();
        }
      })
      .catch((err) => console.log(err));
  }
  removeLoader() {
    let loader = document.getElementById("boxloader");
    loader.classList.remove("boxloader");
  }

  enableBtn() {
    document.getElementById("box-form-btn").disabled = false;
  }

  closeAlert() {
    document.getElementById("boxerrors").innerHTML = "";
  }
}
