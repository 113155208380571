import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log('Hello, Stimulus!', this.element);
    this.waitForImagesToLoad().then(() => {
      try {
        if (!document.execCommand('print', false, null)) { // document.execCommand('print') is for Safari support
          window.print();
        }
      } catch {
        window.print();
      }
    });
  }

  waitForImagesToLoad() {
    const images = Array.from(document.images);
    const promises = images.map((img) => {
      if (img.complete) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        img.onload = img.onerror = resolve;
      });
    });
    return Promise.all(promises);
  }
}