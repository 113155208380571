import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spinner"]

  connect() {
    this.showSpinner = this.showSpinner.bind(this);
    this.hideSpinner = this.hideSpinner.bind(this);
    document.addEventListener('turbo:before-fetch-request', this.showSpinner);
    document.addEventListener('turbo:frame-load', this.hideSpinner);
  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-request', this.showSpinner);
    document.removeEventListener('turbo:frame-load', this.hideSpinner);
  }

  showSpinner(event) {
    const frame = event.target;
    if (frame.id === 'pagination') {
      this.spinnerTarget.classList.remove('hidden');
    }
  }

  hideSpinner(event) {
    const frame = event.target;
    if (frame.id === 'pagination') {
      // Check if there is a next page
      const nextPage = frame.querySelector('[data-next-page]');
      if (!nextPage) {
        this.spinnerTarget.classList.add('hidden');
      }
    }
  }
}