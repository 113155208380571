import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "results", "opportunityName", "opportunityId", "errorMessage"];

  async fetchOpportunities(query) {
    if (query.length >= 2) {
      const url = `/salesforce.json?query=${query}`;
      let csrf = document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content");
      try {
        const response = await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf,
          },
        });
        const data = await response.json();

        if (data.error) throw new Error(data.error);
        if (data.data.length > 0) {
          this.resultsTarget.classList.remove("hidden");
          this.resultsTarget.classList.add("p-4");
          let results = data.data
            .filter((opportunity) => {
              return opportunity.name.toLowerCase().includes(query.toLowerCase());
            })
            .map((opportunity) => {
              return `<li data-action="click->auto-complete#handleSelect touch->auto-complete#handleSelect keydown.enter->auto-complete#handleSelect" value="${opportunity.opportunity_number}" class="dropdown-item cursor-pointer hover:text-dark_green hover:font-bold hover:underline focus-visible:text-dark_green focus-visible:outline-none focus-visible:font-bold focus-visible:underline dark:hover:text-light_green dark:focus-visible:text-light_green" tabIndex="0" >
                      ${opportunity.name}
                    </li>`;
            })
            .join("");

          this.resultsTarget.innerHTML = results;
        } else {
          this.resultsTarget.classList.add("hidden");
          this.opportunityIdTarget.value = "";
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    else {
      this.resultsTarget.classList.add("hidden");
      this.opportunityIdTarget.value = "";
    }
  }

  handleSelect(e) {
    const id = e.target.attributes.value.nodeValue,
      name = e.target.innerText;

    this.opportunityIdTarget.value = id;
    this.opportunityNameTarget.value = name;
    this.resultsTarget.classList.add("hidden");
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden");
  }

  connect() {
    const opportunityName = this.opportunityNameTarget.dataset.autoCompleteSalesforceOpportunityNameValue;
    const opportunityId =  this.opportunityIdTarget.dataset.autoCompleteSalesforceOpportunityIdValue;

    if (opportunityId && opportunityName) {
      const customEvent = {
        target: {
          attributes: {
            value:  {
              nodeValue: opportunityId
            }
          },
          innerText: opportunityName
        }
      };
      this.handleSelect(customEvent);
      this.errorMessageTarget.classList.add("hidden");
    } else if (opportunityName) {
      this.opportunityNameTarget.value = opportunityName;
      this.errorMessageTarget.classList.add("hidden");
    }

    this.opportunityNameTarget.addEventListener("input", (e) => {
      this.fetchOpportunities(e.target.value);
    });

    this.opportunityNameTarget.addEventListener("blur", () => {
      this.hideResults();
    });

    this.resultsTarget.addEventListener("mousedown", (e) => {
      e.preventDefault(); // Prevent blur event when clicking on results
    });
  }
}
